<script>
export default {
	inject: ['manager'],
	methods: {
		selectVariant(data) {
			this.manager.selectedVariantId = data.variantId
		},
	},
}
</script>
<template>
	<div
		v-if="manager.showVariantSelector"
		class="product-attrs font-0 font-md-1 my-2 py-2"
		:class="$b.d && '--desktop'"
	>
		<div
			v-for="attr in manager.visibleVariantsForSelector"
			:key="attr.id"
			@click="selectVariant(attr)"
			class="__chip"
			:class="manager.selectedVariant.id == attr.variantId ? '--selected' : ''"
			:style="{ opacity: attr.outOfStock ? 0.5 : 1 }"
		>
			{{ attr.v }}
		</div>
		<SafeLink v-if="manager.variantsForSelector.length > 4" :to="manager.productRoute">
			<div class="__chip px-3">+</div>
		</SafeLink>
	</div>
</template>

<style scoped lang="scss">
.product-attrs {
	display: flex;
	z-index: 99;
	gap: 6px;
	overflow: auto;

	.__chip {
		color: var(--basetext);
		padding: 6px;
		border: 1px solid var(--link) !important;
		text-align: center;
		border-radius: 4px;
		cursor: pointer;

		&.--selected {
			color: var(--errortext);
			background-color: var(--error);
			border-color: var(--linktext);
		}
	}

	&.--desktop {
		overflow: hidden;

		.__chip {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			min-width: 45px;
		}
	}
}
</style>

